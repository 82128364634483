<template>
  <Block title="预算使用情况">
    <chart-pie :colors="['#3776ED', '#37CBED', '#1ADBA5']" :types="['已采购', '进行中', '未采购']" class="chart" :dataList="dataList"></chart-pie>
  </Block>
</template>

<script>
import ChartPie from '../cmp/pie'
import Block from '../cmp/block'

export default {
  components: {
    Block,
    ChartPie
  },
  props: {
    theme: {
      type: String
    },
    data: {
      type: Object
    }
  },
  computed: {
    dataList () {
      return [{
        name: '已采购',
        value: this.data.usedTotal
      },
      {
        name: '进行中',
        value: this.data.usingTotal
      },
      {
        name: '未采购',
        value: this.data.unusedTotal
      }]
    }
  }
}
</script>

<style scoped lang="less">
.chart {
  width: 100%;
  height: 100%;
}
</style>
