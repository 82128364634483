export const detailFilter = (dataItem, parm) => {
  let pass = true
  if (!parm) {
    return pass
  }
  let keys = Object.keys(parm).filter(v => v != '__key')
  let i = 0
  while (pass && i < keys.length) {
    let key = keys[i]
    pass = dataItem[key] === parm[key]
    i += 1
  }
  return pass
}


export const getBatchInfo = (datas) => {
  if (!datas || datas.length === 0) {
    return null
  }
  let info = {
    startTbTime: null,
    startCsTime: null,
    startEsTime: null,
    startZsTime: null,
    endTime: null,
    haveNormal: false,
    haveAppend: false,
    preTotal: 0,
    cyOrgNum: 0,
    cyLeaderNum: 0,
    isEnd: true,
    fileIds: []
  }
  let cyOrgs = []
  let cyLeaders = []
  let i = 0
  while (i < datas.length) {
    let item = datas[i]
    if (item.type === 'normal') {
      info.haveNormal = true
    } else {
      info.haveAppend = true
    }
    info.isEnd = info.isEnd && item.status === 'end'
    info.preTotal += item.total
    if (item.fileIds) {
      info.fileIds.push(item.fileIds)
    }

    let preOrgIds = item.preOrgIds ? item.preOrgIds.split(',') : []
    preOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    let applyOrgIds = item.applyOrgIds ? item.applyOrgIds.split(',') : []
    applyOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    if (item.finalOrgId) {
      if (!cyOrgs.includes(item.finalOrgId)) {
        cyOrgs.push(item.finalOrgId)
      }
    }

    let fgldUserIds = item.fgldUserIds ? item.fgldUserIds.split(',') : []
    fgldUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)

    if (item.statusActionRecord && item.statusActionRecord.length > 0) {
      let startTb = item.statusActionRecord.find(v => v.statusActionKey === 'creating_switch_draw' && !v.isBack)
      if (startTb) {
        if (info.startTbTime == null || (new Date(info.startTbTime).getTime() > new Date(startTb.createTime).getTime())) {
          info.startTbTime = startTb.createTime
        }
      }
      let startCs = item.statusActionRecord.find(v => v.statusActionKey === 'draw_switch_pre' && !v.isBack)
      if (startCs) {
        if (info.startCsTime == null || (new Date(info.startCsTime).getTime() > new Date(startCs.createTime).getTime())) {
          info.startCsTime = startCs.createTime
        }
      }
      let startEs = item.statusActionRecord.find(v => v.statusActionKey === 'pre_switch_second' && !v.isBack)
      if (startEs) {
        if (info.startEsTime == null || (new Date(info.startEsTime).getTime() > new Date(startEs.createTime).getTime())) {
          info.startEsTime = startEs.createTime
        }
      }
      let startZs = item.statusActionRecord.find(v => v.statusActionKey === 'second_switch_final' && !v.isBack)
      if (startZs) {
        if (info.startZsTime == null || (new Date(info.startZsTime).getTime() > new Date(startZs.createTime).getTime())) {
          info.startZsTime = startZs.createTime
        }
      }
      let end = item.statusActionRecord.find(v => v.statusActionKey === 'final_switch_end' && !v.isBack)
      if (end) {
        if (info.endTime == null || (new Date(info.endTime).getTime() < new Date(end.createTime).getTime())) {
          info.endTime = end.createTime
        }
      }
    }
    i += 1
  }
  if (!info.isEnd) {
    info.endTime = null
  }
  info.cyOrgNum = cyOrgs.length
  info.cyLeaderNum = cyLeaders.length
  info.fileIds = info.fileIds.join(',').split(',').map(v => Number(v))
  return info
}

export const getDetailCount = (datas) => {
  let normalDetailList = []
  let appendDetailList = []
  let unuseList = []
  let usedList = []
  let usingdList = []
  datas.forEach(v => {
    if (v.budgetYearType === 'normal') {
      normalDetailList.push(v)
    } else {
      appendDetailList.push(v)
    }
    if (v.isUsed) {
      usedList.push(v)
    }
    if (v.isUsing) {
      usingdList.push(v)
    }
    if (!v.isUsed && !v.isUsing) {
      unuseList.push(v)
    }
  })
  let data = {
    applyTotal: datas.reduce((c, v) => {
      return c + v.total
    }, 0),
    normal: {
      detail: normalDetailList,
      applyTotal: normalDetailList.reduce((c, v) => {
        return c + v.total
      }, 0),
      unusedTotal: normalDetailList.filter(v => !v.isUsed && !v.isUsing).reduce((c, v) => {
        return c + v.total
      }, 0),
      unusedCount: normalDetailList.filter(v => !v.isUsed && !v.isUsing).length,
      usingTotal: normalDetailList.filter(v => v.isUsing).reduce((c, v) => {
        return c + v.usingTotal
      }, 0),
      usingCount: normalDetailList.filter(v => v.isUsing).length,
      usedTotal: normalDetailList.filter(v => v.isUsed).reduce((c, v) => {
        return c + v.usedTotal
      }, 0),
      usedCount: normalDetailList.filter(v => v.isUsed).length
    },
    append: {
      detail: appendDetailList,
      applyTotal: appendDetailList.reduce((c, v) => {
        return c + v.total
      }, 0),
      unusedTotal: appendDetailList.filter(v => !v.isUsed && !v.isUsing).reduce((c, v) => {
        return c + v.total
      }, 0),
      unusedCount: appendDetailList.filter(v => !v.isUsed && !v.isUsing).length,
      usingTotal: appendDetailList.filter(v => v.isUsing).reduce((c, v) => {
        return c + v.usingTotal
      }, 0),
      usingCount: appendDetailList.filter(v => v.isUsing).length,
      usedTotal: appendDetailList.filter(v => v.isUsed).reduce((c, v) => {
        return c + v.usedTotal
      }, 0),
      usedCount: appendDetailList.filter(v => v.isUsed).length
    },
    unusedTotal: unuseList.reduce((c, v) => {
      return c + v.total
    }, 0),
    usedTotal: usedList.reduce((c, v) => {
      return c + v.usedTotal
    }, 0),
    usingTotal: usingdList.reduce((c, v) => {
      return c + v.usingTotal
    }, 0)
  }
  return data
}