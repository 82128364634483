<template>
  <Block :theme="theme" title="预算情况" @clickBtn="$emit('clickBtn', $event)" :actions="actions">
    <div class="c" v-if="haveNormal && haveAppend">
      <div class="item">
        <div class="item-t">总预算</div>
        <div class="num">
          <Money :money="data.applyTotal"></Money>
          <img src="/static/images/budget/l12.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l1.png" alt="" v-else>
        </div>
      </div>
      <div class="item">
        <div class="item-t">{{budgetYearTypeList[0].label}}</div>
        <div class="num">
          <Money :money="data.normalApplyTotal"></Money>
          <img src="/static/images/budget/l22.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l2.png" alt="" v-else>
        </div>
      </div>
      <div class="item">
        <div class="item-t">{{budgetYearTypeList[1].label}}</div>
        <div class="num">
          <Money :money="data.appendApplyTotal"></Money>
          <img src="/static/images/budget/l32.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l3.png" alt="" v-else>
        </div>
      </div>
    </div>
    <div class="c" v-else-if="haveNormal || haveAppend">
      <div class="item">
        <div class="item-t">预估总预算</div>
        <div class="num">
          <Money :money="data.preTotal"></Money>
          <img src="/static/images/budget/l12.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l1.png" alt="" v-else>
        </div>
      </div>
      <div class="item">
        <div class="item-t">未报预算</div>
        <div class="num">
          <Money :money="data.preTotal - data.applyTotal"></Money>
          <img src="/static/images/budget/l22.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l2.png" alt="" v-else>
        </div>
      </div>
      <div class="item">
        <div class="item-t">已报预算</div>
        <div class="num">
          <Money :money="data.applyTotal"></Money>
          <img src="/static/images/budget/l32.png" alt="" v-if="theme === 'orange'">
          <img src="/static/images/budget/l3.png" alt="" v-else>
        </div>
      </div>
    </div>
  </Block>
</template>

<script>
import Block from '../cmp/block'
import Money from '../cmp/money'

export default {
  components: {
    Block,
    Money
  },
  props: {
    theme: {
      type: String
    },
    data: {
      type: Object
    },
    haveAppend: {
      type: Boolean,
      default: true
    },
    haveNormal: {
      type: Boolean,
      default: true
    },
    actions: { type: Array , default: () => {
      return []
    }}
  },
  computed: {
    budgetYearTypeList () {
      return this.$store.getters.budgetYearTypeList
    }
  }
}
</script>

<style scoped lang="less">
.c {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  .item {
    .item-t {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .num {
      font-size: 30px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #222222;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        position: absolute;
        bottom: 5px;
        right: 0;
        height: 20px;
        width: 63px;
      }
      .dw {
        font-size: 20px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #222222;
        line-height: 30px;
      }
    }
  }
}
</style>
